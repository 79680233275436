import React from "react"
import Navbar from "./Navbar"
import "../styles/global.css"
import { Helmet } from "react-helmet"

export default function Layout({ children }) {
  //   const data = useStaticQuery(graphql`
  //     {
  //       site {
  //         siteMetadata {
  //           copyright
  //         }
  //       }
  //     }
  //   `)
  //   const { copyright } = data.site.siteMetadata

  return (
    <div className="layout">
      <Helmet>
        <title>Salc1's anarchy Server Datamine</title>
      </Helmet>
      <Navbar />
      <div className="content">{children}</div>
      {/* <footer>
        <p>footer</p>
      </footer> */}
    </div>
  )
}
