import React from 'react'
import { Link } from 'gatsby'

export default function Navbar() {

  return (
    <nav>
      <div className="links">
        <Link to="/">Home</Link>
        <Link to="/render">Render</Link>
        <Link to="/statistics">Statistics</Link>
        <Link to="/inv-viewer">Inv-Viewer</Link>
        <Link to="/region-requester">Region Requester</Link>
        <Link to="/downloads">Downloads</Link>
        <Link to="/credits">Credits</Link>
      </div>
    </nav>
  )
}
